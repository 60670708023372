.pure-g > div {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  & > div {
    flex: 1;
  }
}

.l-box {
  padding: 1rem;
}

.hl-box {
  padding: 0 1rem;
}

.vl-box {
  padding: 1rem 0;
}

.l-box-small {
  padding: 0.5rem;
}

.hl-box-small {
  padding: 0 0.5rem;
}

.vl-box-small {
  padding: 0.5rem 0;
}

.flex {
  display: flex;
}

.pure-form input[type=date],
.pure-form input[type=datetime-local],
.pure-form input[type=datetime],
.pure-form input[type=email],
.pure-form input[type=month],
.pure-form input[type=number],
.pure-form input[type=password],
.pure-form input[type=search],
.pure-form input[type=tel],
.pure-form input[type=text],
.pure-form input[type=time],
.pure-form input[type=url],
.pure-form input[type=week],
.pure-form select {
  padding: 0.25em;
}

.pure-form select {
  height: auto;
}