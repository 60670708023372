.teaser {
  background-color: $color-02;
  width: 100%;
  padding: 20px;

  &.taxi,
  &.luftibus {
    color: white;
    display: flex;
    flex-direction: column;

    h2 {
      margin: 0;
    }

    p {
      margin-bottom: 100px;
    }
  }

  &.taxi {
    background-color: $color-03;
    background-image: url($theme_path + "/assets/images/hatscher-teaser-ueber-uns-1808.png");
    background-position: bottom left;
    background-repeat: no-repeat;
  }

  &.luftibus {
    background-color: $color-02;
    background-image: url($theme_path + "/assets/images/hatscher-teaser-luftibus-1808.png"),
                      url($theme_path + '/assets/images/hatscher-teaser-luftibus-icon-1808.png');
    background-position:  bottom left,
                          bottom right;
    background-repeat: no-repeat;

    @media screen and (min-width: $sm){
      background-position: bottom left, bottom -20px right 10px;
    }
  }
}