.top-bar {
  background-color: $color-01;
  text-align: right;
  text-transform: uppercase;
  padding: 5px 0;

  a {
    padding: 10px;
    span {
      .fas {
        color: $color-02!important;

        margin-right: 10px;
      }
    }
  }
}