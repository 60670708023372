.gallery {
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  position: relative;


  br {
    display: none;
    visibility: hidden;
  }

  .gallery-item {
    float: none!important;
    width: auto!important;
  }

  .swiper-slide {

  }

  .swiper-wrapper{
      -webkit-transition-timing-function:linear!important;
      -o-transition-timing-function:linear!important;
      transition-timing-function:linear!important;
  }
}