.accordion {
  .ui-accordion-header {
    margin: 0;
    padding: 10px;
    border: none;
    cursor: pointer;
    outline: none;

    &.ui-state-active {
      border: none;
    }

    .ui-accordion-header-icon {
      margin-right: 5px;
    }
  }

  &.blue {
    .ui-accordion-header {
      &.ui-state-active {
        background-color: $color-02;
      }
    }
  }

  &.red {
    .ui-accordion-header {
      &.ui-state-active {
        background-color: $color-03;
      }
    }
  }
}