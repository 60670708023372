.container-logo-area {
  background-color: white;

  .logo-area-left {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    padding: 0.5rem;

    & > * {
      padding: 0.5rem;
    }
  }

  .logo-area-right {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    padding: 0.5rem;
    flex-wrap: wrap;

    & > * {
      padding: 0.5rem;
    }

    .logo-area-phone {
      font-size: 1.2em;
      white-space: nowrap;

      a {
        img {
          width: 25px;
        }
      }

      span {
        color: $color-04;
        font-size: 1rem;
        margin-left: 28px;
      }

      &.luftibus {
        color: $color-02;
      }

      &.taxi {
        color: $color-03;
      }
    }
  }
}

@media screen and (min-width: $sm){
  .container-logo-area {
    .logo-area-left {
      justify-content: flex-start;
    }

    .logo-area-right {
      justify-content: flex-end;
    }
  }
}