article {
  p {
    line-height: 1.3rem;
  }

  img {
    max-width: 100%;
  }
}

.content-image-container {
  &.right,
  &.left {
    float: none;
  }

  &.md-half-width {
    max-width: 100%;
  }

  &.full-width {
    width: 100%;
  }
}

.content-image {
  max-width: 100%;

  &.right,
  &.left {
    float: none;
  }

  &.md-half-width {
    max-width: 100%;
  }

  &.full-width {
    width: 100%;
  }

  &.bordered {
    padding: 5px;
    border: solid 1px #efefef;
    transition: box-shadow .1s ease-in-out;
    background-color: #efefef;
  }
}

@media screen and (min-width: $md){
  .content-image-container {
    &.right {
      float: right;
    }

    &.left {
      float: left;
    }

    &.md-half-width {
      max-width: 50%;
    }

    &.full-width {
      width: auto;
    }
  }

  .content-image {
    &.right {
      float: right;
    }

    &.left {
      float: left;
    }

    &.md-half-width {
      max-width: 50%;
    }

    &.full-width {
      width: auto;
    }
  }
}