.vertical-tab-teaser {
  border: none!important;
  background-color: transparent!important;

  .ui-tabs-nav {
    background: none!important;
    border: none!important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-basis: 0;
      flex-grow: 2;
      transition: all 0.5s ease;
      outline: 0!important;
      background: none;
      border-radius: 0;
      border-style: solid;
      border-width: 1px;
      border-image: linear-gradient(
                      to left,
                      rgba(255,255,255,0) 1%,
                      rgba(150,150,150,1) 50%,
                      rgba(255,255,255,0) 100%
      ) 100% 0 100% 0/1px 0 1px 0 stretch;

      a {
        transition: all 0.5s ease;
        text-align: center;
        display: block;
        padding: 20px 10px;
        font-weight: bold;
        width: 100%;
        outline: none!important;
      }

      &.ui-state-active,
      &:hover {
        background: $color-03;

        a {
          color: $color-01;
        }
      }
    }
  }

  .ui-tabs-panel {
    padding: 0 1rem;
    margin-top: 20px;
    background-color: #FFFFFF;

    @extend .boxshadow;

    & * {
      font-family: Nunito;
    }
  }
}