.gs-blog-widget-list {
  list-style: none;
  padding: 0;
  margin: 0 -1rem;

  li {
    @extend .hl-box;
    width: 100%;
    display: inline-block;
    vertical-align: top;

    .gs-blog-widget-title {
      font-size: 1.2rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 0;
    }

    .gs-blog-widget-image {
      max-width: 100%;
      height: auto;
    }

    .gs-blog-widget-meta {
      font-size: 0.8rem;
      margin-top: 5px;
      font-style: italic;
      a {
        font-style: normal;
        font-weight: bold;

        &:hover,
        &:active,
        &:focus {
          text-decoration: underline;
        }
      }
    }

    .gs-blog-widget-more {
      display: block;
      border-top: solid 1px $color-02;
      border-bottom: solid 1px $color-02;
      padding: 10px 5px;
      font-weight: bold;
      transition: background-color .5s ease;

      &:hover,
      &:active,
      &:focus {
        background-color: $color-02;
      }
    }
  }
}

.gs-blog-widget-all-container {
  text-align: center;
  margin-top: 25px;

  .gs-blog-widget-all {
    @extend .std-button;
    @extend .std-button.red;
    display: inline-block;
    min-width: 250px;
  }
}



@media screen and (min-width: $sm) {
  .gs-blog-widget-list {
    li {
      width: 50%;
    }
  }
}

@media screen and (min-width: $md) {
  .gs-blog-widget-list {
    li {
      width: 33.3%;
    }
  }
}

/*
.gs-blog-widget-more {
  margin: 10px 0;
}*/
