/* nunito-300 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  src: url($theme_path + '/assets/fonts/nunito-v9-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Nunito Light'), local('Nunito-Light'),
  url($theme_path + '/assets/fonts/nunito-v9-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url($theme_path + '/assets/fonts/nunito-v9-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url($theme_path + '/assets/fonts/nunito-v9-latin-300.woff') format('woff'), /* Modern Browsers */
  url($theme_path + '/assets/fonts/nunito-v9-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url($theme_path + '/assets/fonts/nunito-v9-latin-300.svg#Nunito') format('svg'); /* Legacy iOS */
}


/* nunito-300italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 300;
  src: url($theme_path + '/assets/fonts/nunito-v9-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Nunito Light Italic'), local('Nunito-LightItalic'),
  url($theme_path + '/assets/fonts/nunito-v9-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url($theme_path + '/assets/fonts/nunito-v9-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url($theme_path + '/assets/fonts/nunito-v9-latin-300italic.woff') format('woff'), /* Modern Browsers */
  url($theme_path + '/assets/fonts/nunito-v9-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url($theme_path + '/assets/fonts/nunito-v9-latin-300italic.svg#Nunito') format('svg'); /* Legacy iOS */
}


/* nunito-regular - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url($theme_path + '/assets/fonts/nunito-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Nunito Regular'), local('Nunito-Regular'),
  url($theme_path + '/assets/fonts/nunito-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url($theme_path + '/assets/fonts/nunito-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url($theme_path + '/assets/fonts/nunito-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
  url($theme_path + '/assets/fonts/nunito-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url($theme_path + '/assets/fonts/nunito-v9-latin-regular.svg#Nunito') format('svg'); /* Legacy iOS */
}


/* nunito-italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  src: url($theme_path + '/assets/fonts/nunito-v9-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Nunito Italic'), local('Nunito-Italic'),
  url($theme_path + '/assets/fonts/nunito-v9-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url($theme_path + '/assets/fonts/nunito-v9-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url($theme_path + '/assets/fonts/nunito-v9-latin-italic.woff') format('woff'), /* Modern Browsers */
  url($theme_path + '/assets/fonts/nunito-v9-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url($theme_path + '/assets/fonts/nunito-v9-latin-italic.svg#Nunito') format('svg'); /* Legacy iOS */
}


/* nunito-700 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: url($theme_path + '/assets/fonts/nunito-v9-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Nunito Bold'), local('Nunito-Bold'),
  url($theme_path + '/assets/fonts/nunito-v9-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url($theme_path + '/assets/fonts/nunito-v9-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url($theme_path + '/assets/fonts/nunito-v9-latin-700.woff') format('woff'), /* Modern Browsers */
  url($theme_path + '/assets/fonts/nunito-v9-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url($theme_path + '/assets/fonts/nunito-v9-latin-700.svg#Nunito') format('svg'); /* Legacy iOS */
}


/* nunito-700italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 700;
  src: url($theme_path + '/assets/assets/fonts/nunito-v9-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Nunito Bold Italic'), local('Nunito-BoldItalic'),
  url($theme_path + '/assets/assets/fonts/nunito-v9-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url($theme_path + '/assets/assets/fonts/nunito-v9-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url($theme_path + '/assets/assets/fonts/nunito-v9-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url($theme_path + '/assets/assets/fonts/nunito-v9-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url($theme_path + '/assets/assets/fonts/nunito-v9-latin-700italic.svg#Nunito') format('svg'); /* Legacy iOS */
}


/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url($theme_path + '/assets/fonts/open-sans-v15-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'),
  url($theme_path + '/assets/fonts/open-sans-v15-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url($theme_path + '/assets/fonts/open-sans-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url($theme_path + '/assets/fonts/open-sans-v15-latin-300.woff') format('woff'), /* Modern Browsers */
  url($theme_path + '/assets/fonts/open-sans-v15-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url($theme_path + '/assets/fonts/open-sans-v15-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url($theme_path + '/assets/fonts/open-sans-v15-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
  url($theme_path + '/assets/fonts/open-sans-v15-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url($theme_path + '/assets/fonts/open-sans-v15-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url($theme_path + '/assets/fonts/open-sans-v15-latin-300italic.woff') format('woff'), /* Modern Browsers */
  url($theme_path + '/assets/fonts/open-sans-v15-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url($theme_path + '/assets/fonts/open-sans-v15-latin-300italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url($theme_path + '/assets/fonts/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url($theme_path + '/assets/fonts/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url($theme_path + '/assets/fonts/open-sans-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url($theme_path + '/assets/fonts/open-sans-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
  url($theme_path + '/assets/fonts/open-sans-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url($theme_path + '/assets/fonts/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url($theme_path + '/assets/fonts/open-sans-v15-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
  url($theme_path + '/assets/fonts/open-sans-v15-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url($theme_path + '/assets/fonts/open-sans-v15-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url($theme_path + '/assets/fonts/open-sans-v15-latin-italic.woff') format('woff'), /* Modern Browsers */
  url($theme_path + '/assets/fonts/open-sans-v15-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url($theme_path + '/assets/fonts/open-sans-v15-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url($theme_path + '/assets/fonts/open-sans-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url($theme_path + '/assets/fonts/open-sans-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url($theme_path + '/assets/fonts/open-sans-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url($theme_path + '/assets/fonts/open-sans-v15-latin-700.woff') format('woff'), /* Modern Browsers */
  url($theme_path + '/assets/fonts/open-sans-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url($theme_path + '/assets/fonts/open-sans-v15-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url($theme_path + '/assets/fonts/open-sans-v15-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
  url($theme_path + '/assets/fonts/open-sans-v15-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url($theme_path + '/assets/fonts/open-sans-v15-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url($theme_path + '/assets/fonts/open-sans-v15-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url($theme_path + '/assets/fonts/open-sans-v15-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url($theme_path + '/assets/fonts/open-sans-v15-latin-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}