.container-menu-main {
  position: relative;
  overflow: hidden;
  top: 0;
  width: 100%;
  background-color: white;
  -webkit-box-shadow: 0 10px 30px -15px rgba(0,0,0,1);
  box-shadow: 0 10px 30px -15px rgba(0,0,0,1);

  .mobile-burger-button {
    background-color: $color-01;
    padding: 14px 0;
    text-align: right;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    a.icon {
      padding: 0 15px;
    }
  }


  #container-inner-menu-main {
    display: none;

    .nav-desktop-facebook {
      display: none;
    }
  }

  nav {
    .sm-simple {
      border: none;
      box-shadow: none;
      margin: 0;
      padding: 0;
      background: none;
      float: left;
      display: block;
      width: 100%;

      &.sm-rtl li {
        text-align: left;
        direction: ltr;
      }
    }
  }
}

.sm-simple a,
.sm-simple a:active,
.sm-simple a:focus,
.sm-simple a:hover {
  font-family: Nunito!important;
}

@media screen and (min-width: $md){
  .container-menu-main {
    background-color: $color-01;
    box-shadow: none;
    overflow: inherit;

    #container-inner-menu-main {
      display: inherit!important;

      .nav-desktop-facebook {
        display: inline-block!important;
        align-self: flex-end;
        text-align: right;

        img {
          padding: 6px 0;
          padding-right: 20px;
        }
      }
    }


    .mobile-burger-button {
      display: none;
    }

    nav {
      .sm-simple {


        li {
          display: inline;
          border: none;

          ul {
            border-top: solid 3px $color-03;
            margin-top: 0!important;
          }

          a {
            font-weight: bold;
            text-transform: uppercase;
          }

          &:hover,
          &:active,
          &.current_page_item {
            border-bottom: solid 3px $color-03;
            margin-bottom: -3px;
            background: none;
            & > a {
              color: $color-03;
              background: none;
            }
          }
        }

        &.main-menu-second {
          text-align: right;
        }
      }
    }
  }
}