*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html, body {
  font-size: 18px;

  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;

  max-width: 1920px;
}

html,
body,
button,
input,
select,
textarea,
a,
.pure-g [class *= "pure-u"] {
  font-family: 'Nunito', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

th strong {
    text-transform: uppercase;
}

.round-border {
  border: solid 2px #fff;
  border-radius: 10px;
  padding: 10px;
}

#taxide-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $color-03;
  min-height: 300px;
  padding: 20px;

  h2 {
    margin: 0;
    padding: 0;
    color: white;
    align-self: flex-start;
  }

  .outer-container {
    text-align: center;
    flex-grow: 1;
    display: flex;
    align-items: center;

    .inner-container {

      .img-container {
        @extend .boxshadow;
        background-color: white;

        img {
          display: inline-block;
        }
      }

      p {
        font-size: 1.2rem;
        color: white;
      }
    }
  }

  a {
    min-width: 50%;
    /*    margin: auto;*/
  }
}

.xdsoft_datetimepicker {
  z-index: 9999999999!important;
}

#luftibus-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $color-02;
  min-height: 300px;
  padding: 20px;

  h2 {
    margin: 0;
    padding: 0;
    color: white;
    align-self: flex-start;
  }

  .outer-container {
    text-align: center;
    flex-grow: 1;
    display: flex;
    align-items: center;

    .inner-container {

      .img-container {
        @extend .boxshadow;
        background-color: white;
        color: $color-02;
        padding: 6.5px 0;
        letter-spacing: 5px;
        font-size: 1.1rem;

        img {
          display: inline-block;
        }
      }

      p {
        font-size: 1.2rem;
        color: white;
      }
    }
  }

  a {
    min-width: 50%;
    /*    margin: auto;*/
  }
}



.iframe-taxi-container {
  position: relative;
  z-index: 999;
  margin-top: 0;
}

@media screen and (min-width: $md) {
  .iframe-taxi-container {
    margin-top: -80px;
    margin-bottom: 0;
  }
}

.gs-login-form-container {
  @extend .boxshadow;
  background-color: $color-02 !important;

  .pure-control-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    label {
      width: auto;
      color: white;
      margin-top: 0.5em;
    }
  }

  .pure-controls {
    text-align: center;
    margin-left: 0;

    button {
      min-width: 50%;
    }
  }
}

.fancybox-slide--iframe .fancybox-content {
  max-width: 730px;
}

.color-blue {
  color: $color-02;
}

.subtitle-caption {
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
    font-weight: bold;

    &.small {
      font-size: 0.9em;
    }
  }
}

.no-border {
  border: none;
}

.boxshadow {
  -webkit-box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 1);
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 1);
}

.page-background-image {
  background-color: #dee7f1;
  /*background-image: url($theme_path + '/assets/images/hatscher-bg-1920x3000-1808.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;*/
}

.bottom-footer-image {
  background-image: url($theme_path + '/assets/images/background.page.bottom.png');
  background-size: cover;
  background-position-x: center;
  background-position-y: 30px;
  background-repeat: no-repeat;
  height: 182px;
}

.crop-header-image {
  max-height: 600px;
  overflow: hidden;
  position: relative;
}

.image-visibility {
  display: none;

  &.always {
    display: inline;
  }

  &.sm {
    @media screen and(min-width: $sm) {
      display: inline;
    }
  }

  &.md {
    @media screen and (min-width: $md) {
      display: inline;
    }
  }

  &.lg {
    @media screen and (min-width: $lg) {
      display: inline;
    }
  }

  &.xl {
    @media screen and (min-width: $xl) {
      display: inline;
    }
  }
}

.block {
  text-align: justify;

  & * {
    text-align: justify;
  }
}

.check-list {
  list-style: none;

  li {
    margin: 15px 0;

    &:before {
      @extend .fas;

      content: "\f00c";

      display: inline-block;
      margin-left: -1.3em; /* same as padding-left set on li */
      width: 1.3em; /* same as padding-left set on li */
    }
  }

  &.red {
    li:before {
      color: $color-03;
    }
  }
}

.taxi-service-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: baseline;
  margin-bottom: 20px;

  img {
    max-width: 110px;
    width: 20%;

    padding: 10px;
  }
}

img.alignright { float: right; margin: 0 0 1em 1em; }
img.alignleft { float: left; margin: 0 1em 1em 0; }
img.aligncenter { display: block; margin-left: auto; margin-right: auto; }
.alignright { float: right; }
.alignleft { float: left; }
.aligncenter { display: block; margin-left: auto; margin-right: auto; }
.single-page {
  img {
    max-width: 100%;
    height: auto;
  }
}


.single-post-thumbnail-container {
  text-align: center;

  .single-post-thumbnail {
    position: relative;
    z-index: 999;
    width: 200px;
    height: 200px;
    background-size: cover;
    background-position: center center;
    margin: -100px 1rem 1rem 1rem;
    display: inline-block;
    border: solid 5px white;
    @extend .boxshadow;

    a {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .single-post-thumbnail-caption {
    display: block;
    vertical-align: top;
    margin: 0;
  }
}

@media screen and (min-width: $sm){
  .single-post-thumbnail-container {
    text-align: left;
    .single-post-thumbnail {
      display: inline-block;
    }

    .single-post-thumbnail-caption {
      display: inline-block;
      vertical-align: top;
      margin: 1rem 0;
    }
  }
}

.responsive-column-list {
  columns: 1;
  -webkit-columns: 1;
  -moz-columns: 1;
  -moz-column-gap: 50px;
  -webkit-column-gap: 50px;
  column-gap: 50px;
  padding: 0px 20px;

  li {
    margin: 0 0 20px 0;
  }

  @media screen and(min-width: $md) {
    &.three-columns,
    &.two-columns {
      columns: 2;
      -moz-columns: 2;
      -webkit-columns: 2;

      column-count: 2;
      -moz-column-count: 2;
      -webkit-column-count: 2;
    }
  }

  @media screen and(min-width: $lg) {
    &.three-columns {
      columns: 3;
      -moz-columns: 3;
      -webkit-columns: 3;

      column-count: 3;
      -moz-column-count: 3;
      -webkit-column-count: 3;
    }
  }
}
