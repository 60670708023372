.pure-g > div {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.container-1000 {
  width: 100%;
  max-width: $max-content-width;
  margin: auto;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.booking-formular-container {
  .fancybox-content {
    background-color: $color-02;
  }
}