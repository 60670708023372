footer {
  background-color: white;
  background-image: linear-gradient(to right,$color-03 50%,rgba(255,255,255,0) 0%);
  background-position: top;
  background-size: 15px 2px;
  background-repeat: repeat-x;
  padding-top: 2px;
  .footer {
    background-color: white;

    margin-top: 15px;
    margin-bottom: 40px;

    .footer-icon-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      p {
        color: $color-04;
        font-family: "Open Sans";

        img {
          vertical-align: middle;
          margin-right: 10px;
        }
      }
    }
  }

  .sub-footer {
    background-color: $color-02;

    p {
      text-align: center;
      color: white;
      padding: 10px;
      margin: 0;
    }
  }
}