.std-button {
  transition: all .2s linear;
  border: solid 2px white;
  text-align: center;
  border-radius: 10px;
  padding: 5px;
  display: inline-block;

  &:hover {
    cursor: pointer;
  }

  &.block {
    display: block;
    width: 100%;
  }

  &.red {
    background-color: $color-03;
    color: white;

    &:hover {
      border: solid 2px $color-03;
      color: $color-03;
      background-color: white;
    }

    &.outline {
      background-color: transparent;
      border-color: $color-03;
      color: $color-03;

      &:hover {
        border: solid 2px white;
        color: white;
        background-color: $color-03;
      }
    }
  }

  &.blue {
    background-color: $color-02;
    color: white;

    &:hover {
      border: solid 2px $color-02;
      color: $color-02;
      background-color: white;
    }

    &.outline {
      background-color: transparent;
      border-color: $color-02;
      color: $color-02;

      &:hover {
        border: solid 2px white;
        color: white;
        background-color: $color-02;
      }
    }

    &.address-delete {
      &:before {
        content: "";
        display: inline-block;
        width: 23px;
        height: 23px;
        background-image: url($theme + "/assets/images/icon-adresse-weiss.png");
        background-repeat: no-repeat;
        background-size: contain;
      }

      &:hover,
      &:focus,
      &:active {
        &:before {
          background-image: url($theme + "/assets/images/icon-adresse-blau.png");
        }
      }
    }

    &.luggage-delete {
      &:before {
        content: "";
        display: inline-block;
        width: 23px;
        height: 23px;
        background-image: url($theme + "/assets/images/icon-gepaeck-weiss.png");
        background-repeat: no-repeat;
        background-size: contain;
      }

      &:hover,
      &:focus,
      &:active {
        &:before {
          background-image: url($theme + "/assets/images/icon-gepaeck-blau.png");
        }
      }
    }
  }

  &:disabled {
    background-color: $color-04!important;

    &:hover {
      border: solid 2px white;
      color: white;
    }
  }
}