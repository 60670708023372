.luftibus-booking-inner-container.fancybox-content {
  max-width: 100%;
  min-width: 35%;
  background-color: $color-02;
  padding: 1rem;

  .gs-login-user-container {
    span:not(.bonus-points, .bonus-number) {
      color: white;
    }

    .bonus-points {
      color: #A5FFA5;

      .bonus-number {
        color: #A5FFA5;
        font-size: 1.2rem;
      }
    }
  }

  #booking-thanks-container {
    color: white;
  }

  #form-luftibus-booking {
    .accordion-booking {
      .ui-accordion-header {
        border: solid 2px white;
        border-radius: 10px;
        padding: 5px;
        margin: 5px 0;
        color: $color-02;
        background-color: white;
      }

      .ui-accordion-header.ui-state-active {
        background-color: $color-02;
        color: white;
      }

      .ui-accordion-content {
        background-color: $color-02;
        border: solid 2px white;
        border-radius: 10px;
        padding: 10px;
      }
    }
  }

  h2, h3 {
    margin: 0;
    padding: 0;
    color: white;
  }

  h3 {
    font-weight: normal;
    //margin-bottom: 10px;
  }

  form {
    margin: 0;

    label, span {
      color: white;
    }

    #oneway-container,
    #return-container {
      label, span {
        display: inline-block;
        margin-top: 10px;
        margin-bottom: 0;
      }
    }

    .button-container {
      text-align: center;

      button {
        min-width: 50%;
        align-self: center;
      }
    }
  }

  .booking-overview {
    color: white;
  }

  @media screen and (min-width: 600px){
    .luftibus-booking-inner-container.fancybox-content {
      max-width: 600px;
    }
  }

  // ------------------------------
  // Checkbox ---------------------
  // ------------------------------

  .container {
    display: inline!important;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: white;
    border: solid 2px $color-02;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: white;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: white;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid $color-02;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}