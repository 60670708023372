.booking-teaser {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $color-02;
  padding: 10px;
  align-items: center;
  flex-wrap: wrap;

  .booking-teaser-caption {
    text-align: center;
    margin-top: 0;
    color: white;
    width: 100%;
  }

  .booking-teaser-icon-container {
    text-align: center;
    max-width: 50%;
    border: solid 10px darken($color-02, 10);
    border-radius:50%;
    padding: 10%;
    margin: auto;
   /* -webkit-box-shadow: 0 0 0 10px lighten($color-02, 20);
    box-shadow: 0 0 0 10px lighten($color-02, 20);*/
  }

  .left-container {
    width: 100%;
  }

  .right-container {
    text-align: center;
    color: white;
    margin: 0;
    width: 100%;
    margin-top: auto;

    a {
      display: block;
      transition: all 0.3s linear;
      padding: 20px 0;

      border-style: solid;
      border-width: 1px;
      border-image: linear-gradient(
                      to left,
                      rgba(255,255,255,0) 1%,
                      rgba(255,255,255,1) 50%,
                      rgba(255,255,255,0) 100%
      ) 100% 0 100% 0/1px 0 1px 0 stretch;


      &:hover,
      &:active,
      &:focus {
        background: darken($color-02, 10);
      }
    }
  }

  .center-container {
    .booking-teaser-content {
      padding: 10px 0;
      color: white;

      ul {
        list-style: none;

        li {
          margin: 15px 0;

          &:before {
            @extend .fas;

            content: "\f00c";

            display: inline-block;
            margin-left: -1.3em; /* same as padding-left set on li */
            width: 1.3em; /* same as padding-left set on li */
          }
        }
      }
    }
  }
}

@media screen and (min-width: $sm) and (max-width: $md) {
  .booking-teaser {
    display: flex;
    flex-direction: row;
    padding: 20px 0;

    .left-container,
    .center-container,
    .right-container {
      width: 33.3%;
    }

    .left-container {
      display: flex;
      flex-direction: column;

      .booking-teaser-icon-container {
        align-self: center;
        margin: 0 auto;
      }
    }

    .center-container {
      display: flex;
      align-items: center;
    }

    .right-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin: auto;
    }
  }
}