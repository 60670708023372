.blog-archive-teaser {
  background-color: white;
  padding: 10px;
  background: darken($color-01, 20);//linear-gradient(100deg, $color-03 0%, $color-03 50%, $color-02 50.1%, $color-02 50%);

  &.hatscher {
    background: $color-03;
  }

  &.luftibus {
    background: $color-02;
  }

  h2 {
    font-size: 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
    margin-top: 0;
  }

  .blog-archive-teaser-content {
    color: white;
  }

  .blog-archive-teaser-permalink {
    width: 100%;
    display: block;
  }
}

.grid-item {
  width: 100%;
  padding: 1rem;
}

@media screen and (min-width: $sm){
  .grid-item {
    width: 50%;
  }
}

@media screen and (min-width: $md){
  .grid-item {
    width: 33.3%;
  }
}